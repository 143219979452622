import {Component, Input} from '@angular/core';
import {NgClass, NgStyle, NgTemplateOutlet} from "@angular/common";
import {ResumeHasSocialLinkOutDto} from "../../common/resume-section-model/social-link.model";
import {ClassicLayoutModel, ResumeThemeModel} from "../../common/theme/resume-theme.model";
import {SOCIAL_LINK} from '../../common/social-link.model';
import {RESUME_ICON_LIST} from '../../common/resume-section-model/resume.model';
import {RESUME_SECTION_TYPE_DISPLAY} from "../../common/resume-section.model";
import {SOCIAL_LINK_DISPLAY} from "../../../website/common/website-model/social-link-out.dto";
import {ResumeClassicLayoutUtil} from "../../common/theme/resume-classic-layout.util";
import {LinkUtil} from "../../../../common/util/link.util";

@Component({
  selector: 'app-r-social-link',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    NgStyle
  ],
  templateUrl: './r-social-link.component.html',
  styleUrl: './r-social-link.component.scss'
})
export class RSocialLinkComponent {
  @Input()
  resumeTheme?: ResumeThemeModel;
  @Input()
  socialLinks: ResumeHasSocialLinkOutDto[];
  @Input()
  isClassicType: boolean = false;

  SOCIAL_LINK = SOCIAL_LINK;
  RESUME_ICON_LIST = RESUME_ICON_LIST;
  classicLayoutTheme: ClassicLayoutModel = ResumeClassicLayoutUtil.getClassicLayoutTheme();
  protected readonly RESUME_SECTION_TYPE_DISPLAY = RESUME_SECTION_TYPE_DISPLAY;
  protected readonly SOCIAL_LINK_DISPLAY = SOCIAL_LINK_DISPLAY;

  openUrlInNewTab(name: string): void {
      LinkUtil.openLinkInNewTab(name);
  }
}
