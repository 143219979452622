<div [ngClass]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? 'p-4' : 'p-0' "
     [style.grid-template-columns]="layoutType === LAYOUT_TYPE.ONE_COLUMN && profileImage? 'fit-content(100%) 1fr' :'1fr' "
     [style.border]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? '2px solid var(--color-brown-extra-light)':'none'"
     [style.background]="
      layoutType === LAYOUT_TYPE.ONE_COLUMN &&  isClassicType ? classicLayoutTheme?.headerColor :
      layoutType === LAYOUT_TYPE.TWO_COLUMN && isClassicType ? classicLayoutTheme?.backgroundColor :
      layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.highlightedBackground : resumeTheme?.pageBackground"
     [style.min-height]="layoutType === LAYOUT_TYPE.ONE_COLUMN ?'150px':'fit-content'"
     class="one-column-header-height grid">
  @if (profileImage && layoutType === 'ONE_COLUMN' && !isClassicType) {
    <img [src]="profileImage"
         alt="profileImage"
         [style.max-width]="'160px'"
         [style.min-width]="'100px'"
         [style.min-height]="'100px'"
         [style.max-height]="'160px'"
         class="w-full fade-in rounded-3xl"/>
  }
  @if (resumeHasPersonalInformationOutDto) {
    <div [ngClass]="layoutType === LAYOUT_TYPE.TWO_COLUMN ? 'min-h-28 sm:min-h-48' : 'h-full'">
      <div class="flex flex-col items-start justify-between h-full"
           [ngClass]="(layoutType === LAYOUT_TYPE.ONE_COLUMN && profileImage && !isClassicType) ? 'pl-4' : 'pl-0'">
        <div class="flex flex-col items-start justify-between w-full">
          <div class="flex flex-col items-start justify-star w-full">
            <div class="flex items-center justify-between w-full title-container">
              <div class="font-medium capitalize text-sm leading-4 sm:text-3xl sm:leading-9"
                   [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                {{ resumeHasPersonalInformationOutDto?.fullName | titlecase }}
              </div>
              @if (socialLinks && !isClassicType) {
                <app-r-social-link [resumeTheme]="resumeTheme" [socialLinks]="socialLinks"></app-r-social-link>
              }
            </div>
            <div class="font-normal text-xs leading-4 sm:text-lg sm:leading-7"
                 [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
              {{ resumeHasPersonalInformationOutDto?.jobTitle }}
            </div>
          </div>
        </div>
        <div class="candidate-contact-info pt-2 flex flex-col items-start justify-between">
          <div class="flex flex-col items-start justify-start">
            <div class="flex flex-col sm:flex-row sm:gap-2">
              @if (resumeHasPersonalInformationOutDto?.phone) {
                <div class="flex flex-row items-center justify-start">
                  @if (!isClassicType) {
                    <div class="text-xs sm:text-base font-normal pr-1"
                         [ngClass]="CONTACT_NUMBER_TYPE_ICON[resumeHasPersonalInformationOutDto?.contactNumberType] || 'ti ti-phone'"
                         [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                    </div>
                  }
                  <div class="font-normal text-xxs sm:text-sm"
                       [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                    {{ resumeHasPersonalInformationOutDto?.phone }}
                  </div>
                </div>
              }
              @if (resumeHasPersonalInformationOutDto?.alternateContactNumber && resumeHasPersonalInformationOutDto?.phone) {
                <div
                  class="hidden sm:block"
                  [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                  |
                </div>
              }
              @if (resumeHasPersonalInformationOutDto?.alternateContactNumber) {
                <div class="flex flex-row items-center justify-start">
                  @if (!isClassicType) {
                    <div class="text-xs sm:text-base font-light pr-1"
                         [ngClass]="CONTACT_NUMBER_TYPE_ICON[resumeHasPersonalInformationOutDto?.alternateContactNumberType] || 'ti ti-phone'"
                         [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                    </div>
                  }
                  <div class="font-normal text-xxs sm:text-sm"
                       [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                    {{ resumeHasPersonalInformationOutDto?.alternateContactNumber }}
                  </div>
                </div>
              }
            </div>
            @if (resumeHasPersonalInformationOutDto?.emailAddress) {
              <div class="flex flex-row items-center justify-start">
                @if (!isClassicType) {
                  <div class="font-normal text-xs sm:text-base pr-1"
                       [ngClass]="resumeIcon.email"
                       [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                  </div>
                }
                <div class="text-xxs sm:text-sm font-normal"
                     [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                  {{ resumeHasPersonalInformationOutDto?.emailAddress }}
                </div>
              </div>
            }
          </div>
          @if (resumeHasPersonalInformationOutDto?.city || resumeHasPersonalInformationOutDto?.country) {
            <div [ngStyle]="{'color': resumeTheme.primaryColor}"
                 class="locations-details-blue flex flex-row items-center justify-start">
              @if (!isClassicType) {
                <div class="text-xs sm:text-base font-normal"
                     [ngClass]="resumeIcon.location"
                     [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                </div>
              }
              @if (resumeHasPersonalInformationOutDto?.city) {
                <div class="text-xxs sm:text-sm font-normal"
                     [ngClass]="isClassicType ? 'ml-0' : 'ml-1' "
                     [class.resume-card-location-ellipsis]="resumeHasPersonalInformationOutDto?.city?.length > 18"
                     [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                  {{ resumeHasPersonalInformationOutDto?.country ? (resumeHasPersonalInformationOutDto?.city | titlecase) + ',' : (resumeHasPersonalInformationOutDto?.city| titlecase) }}
                </div>
              }
              @if (resumeHasPersonalInformationOutDto?.country) {
                <div class="text-xxs sm:text-sm font-normal ml-1"
                     [style.color]="layoutType === LAYOUT_TYPE.ONE_COLUMN ? resumeTheme.oneColumnHighlightedPrimaryText : resumeTheme.primaryColor">
                  {{ resumeHasPersonalInformationOutDto?.country | titlecase }}
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
