<div class="mb-2 text-base text-heading-light font-medium">Themes</div>
<div class="grid grid-cols-4 gap-2">
  @for (theme of resumeThemes; track theme?.themeType) {
    <div class="w-max items-center m-auto">
      <div class="p-2 resume-theme-outer-container w-max m-auto cursor-pointer"
           [ngClass]="{'inactive-button-layout-button':theme.themeType !== resumeThemeType,'active-button-layout-button':theme.themeType === resumeThemeType}"
           (click)="selected(theme)">
        <div class="resume-theme-content" [style.background-color]="theme?.backgroundColor">
        </div>
      </div>
      <div class="text-xs text-center mt-2">{{ theme?.title }}</div>
    </div>
  }
</div>
