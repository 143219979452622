import {ResumeThemeModel} from "./resume-theme.model";
import {RESUME_THEME_TYPE, RESUME_THEME_TYPE_DISPLAY} from "../resume.model";
import {LAYOUT_TYPE} from "../layout-selection.model";
import {
  PORTFOLIO_TYPE,
  ResumeThemeSelectionModel
} from "../../../ai-resume/common/component/resume-theme-indicator/resume-theme-indicator.component";

export class ResumeThemeUtil {

  public static getResumeTheme(layoutType: string, theme: string): any {
    return layoutType == LAYOUT_TYPE.ONE_COLUMN ? this.getThemeForOneColumn(theme) : this.getThemeForTwoColumn(theme);
  }

  private static getThemeForOneColumn(theme: string): ResumeThemeModel {
    switch (theme) {
      case RESUME_THEME_TYPE.ISTANBUL:
        return ResumeThemeUtil.getIstanbulThemeForOneColumn();
      case RESUME_THEME_TYPE.PUNE:
        return ResumeThemeUtil.getPuneThemeForOneColumn();
      case RESUME_THEME_TYPE.BERLIN:
        return ResumeThemeUtil.getBerlinThemeForOneColumn();
      case RESUME_THEME_TYPE.NAIROBI:
        return ResumeThemeUtil.getNairobiThemeForOneColumn();
      case RESUME_THEME_TYPE.MIAMI:
        return ResumeThemeUtil.getMiamiThemeForOneColumn();
      case RESUME_THEME_TYPE.PARIS:
        return ResumeThemeUtil.getParisThemeForOneColumn();
      case RESUME_THEME_TYPE.TOKYO:
        return ResumeThemeUtil.getTokyoThemeForOneColumn();
      case RESUME_THEME_TYPE.MUMBAI:
        return ResumeThemeUtil.getMumbaiThemeForOneColumn();
      default:
        return ResumeThemeUtil.getIstanbulThemeForOneColumn();
    }
  }

  private static getThemeForTwoColumn(theme: string): ResumeThemeModel {
    switch (theme) {
      case RESUME_THEME_TYPE.ISTANBUL:
        return ResumeThemeUtil.getIstanbulThemeForTwoColumn();
      case RESUME_THEME_TYPE.PUNE:
        return ResumeThemeUtil.getPuneThemeForTwoColumn();
      case RESUME_THEME_TYPE.BERLIN:
        return ResumeThemeUtil.getBerlinThemeForTwoColumn();
      case RESUME_THEME_TYPE.NAIROBI:
        return ResumeThemeUtil.getNairobiThemeForTwoColumn();
      case RESUME_THEME_TYPE.MIAMI:
        return ResumeThemeUtil.getMiamiThemeForTwoColumn();
      case RESUME_THEME_TYPE.PARIS:
        return ResumeThemeUtil.getParisThemeForTwoColumn();
      case RESUME_THEME_TYPE.TOKYO:
        return ResumeThemeUtil.getTokyoThemeForTwoColumn();
      case RESUME_THEME_TYPE.MUMBAI:
        return ResumeThemeUtil.getMumbaiThemeForTwoColumn();
      default:
        return ResumeThemeUtil.getIstanbulThemeForTwoColumn();
    }
  }

  static getThemeForClassicLayout(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-black-light-dark)",
      "pageBackground": "var(--color-white)",
      "oneColumnHighlightedPrimaryText": "var(--color-black-light-dark)",
      "oneColumnHighlightedSecondaryText": "var(--color-black-light-dark)",
      "primaryColor": "var(--color-black-light-dark)",
      "secondaryColor": "var(--color-light-gray)",
      "skillChipBackground": "transparent",
      "skillChipTextColor": "var(--color-black-light-dark)",
      "iconColor": "var(--color-black-light-dark)",
      "twoColumnHighlightedPrimaryText": "var(--color-black-light-dark)",
      "twoColumnHighlightedSecondaryText": "var(--color-light-gray)",
      "twoColumnHighlightedHeadingText": "var(--color-black-light-dark)",
      "highlightedChipBackground": "transparent",
      "highlightedChipText": "var(--color-black-light-dark)"
    } as ResumeThemeModel;
  }

  public static getMumbaiThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white-extra-dark)",
      "pageBackground": "var(--color-white-extra-dark)",
      "oneColumnHighlightedPrimaryText": "var(--color-tertiary)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-tertiary)",
      "secondaryColor": "var(--color-gray)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-gray-dark)",
      "iconColor": "var(--color-extra-light-primary)"
    } as ResumeThemeModel;
  }

  private static getTokyoThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-green-light-dark)",
      "pageBackground": "var(--color-green-light-extra)",
      "oneColumnHighlightedPrimaryText": "var(--color-white)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-green-light-dark)",
      "secondaryColor": "var(--color-green-extra-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-green-light)",
      "iconColor": "var(--color-light-green)"
    } as ResumeThemeModel;
  }

  private static getParisThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-purple-white-dark)",
      "oneColumnHighlightedPrimaryText": "var(--color-purple)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-purple)",
      "secondaryColor": "var(--color-brown-light)",
      "skillChipBackground": "var(--color-brown-extra-light)",
      "skillChipTextColor": "var(--color-purple)",
      "iconColor": "var(--color-purple-light)"
    } as ResumeThemeModel;
  }

  private static getMiamiThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-green-light-extra)",
      "oneColumnHighlightedPrimaryText": "var(--color-green-light-dark)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-green-light-dark)",
      "secondaryColor": "var(--color-green-extra-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-green-light)",
      "iconColor": "var(--color-light-green)"
    } as ResumeThemeModel;
  }

  private static getNairobiThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-extra-white-dark-light)",
      "oneColumnHighlightedPrimaryText": "var(--color-extra-dark-brown)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-extra-dark-brown)",
      "secondaryColor": "var(--color-extra-brown-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-extra-light)",
      "iconColor": "var(--color-brown)"
    } as ResumeThemeModel;
  }

  private static getBerlinThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-extra-dark-brown)",
      "pageBackground": "var(--color-extra-white-dark-light)",
      "oneColumnHighlightedPrimaryText": "var(--color-white)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-extra-dark-brown)",
      "secondaryColor": "var(--color-extra-brown-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-extra-light)",
      "iconColor": "var(--color-brown)"
    } as ResumeThemeModel;
  }

  private static getPuneThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-purple)",
      "pageBackground": "var(--color-white-slight-blue)",
      "oneColumnHighlightedPrimaryText": "var(--color-white)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-purple)",
      "secondaryColor": "var(--color-brown-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-purple-dark)",
      "iconColor": "var(--color-yellow)"
    } as ResumeThemeModel;
  }

  private static getIstanbulThemeForOneColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-tertiary)",
      "pageBackground": "var(--color-white-extra-dark)",
      "oneColumnHighlightedPrimaryText": "var(--color-white)",
      "oneColumnHighlightedSecondaryText": "",
      "primaryColor": "var(--color-tertiary)",
      "secondaryColor": "var(--color-gray)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-gray-dark)",
      "iconColor": "var(--color-extra-light-primary)"
    } as ResumeThemeModel;
  }

  private static getMumbaiThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-white-extra-dark)",
      "primaryColor": "var(--color-tertiary)",
      "secondaryColor": "var(--color-gray)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-gray-dark)",
      "highlightedChipBackground": "var(--color-white-extra-light)",
      "highlightedChipText": "var(--color-tertiary)",
      "iconColor": "var(--color-extra-light-primary)",
      "twoColumnHighlightedPrimaryText": "var(--color-tertiary)",
      "twoColumnHighlightedSecondaryText": "var(--color-gray)",
      "twoColumnHighlightedHeadingText": "var(--color-tertiary)"
    } as ResumeThemeModel;
  }

  private static getTokyoThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-green-light-dark)",
      "pageBackground": "var(--color-green-light-extra)",
      "primaryColor": "var(--color-green-light-dark)",
      "secondaryColor": "var(--color-green-extra-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "highlightedChipBackground": "var(--color-green-light)",
      "highlightedChipText": "var(--color-white)",
      "skillChipTextColor": "var(--color-green-light)",
      "iconColor": "var(--color-light-green)",
      "twoColumnHighlightedPrimaryText": "var(--color-green-extra-white)",
      "twoColumnHighlightedSecondaryText": "var(--color-green-extra-light)",
      "twoColumnHighlightedHeadingText": "var(--color-green-extra-white)"
    } as ResumeThemeModel;
  }

  private static getParisThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-white-slight-blue)",
      "primaryColor": "var(--color-purple)",
      "secondaryColor": "var(--color-brown-light)",
      "skillChipBackground": "var(--color-brown-extra-light)",
      "skillChipTextColor": "var(--color-purple-extra-light)",
      "highlightedChipBackground": "var(--color-purple-white-dark)",
      "highlightedChipText": "var(--color-purple)",
      "iconColor": "var(--color-purple-light)",
      "twoColumnHighlightedPrimaryText": "var(--color-purple)",
      "twoColumnHighlightedSecondaryText": "var(--color-brown-light)",
      "twoColumnHighlightedHeadingText": "var(--color-purple)"
    } as ResumeThemeModel;
  }

  private static getMiamiThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-extra-light-green)",
      "primaryColor": "var(--color-green-light-dark)",
      "secondaryColor": "var(--color-green-extra-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-green-light)",
      "highlightedChipBackground": "var(--color-green-extra-white)",
      "highlightedChipText": "var(--color-green-light-dark)",
      "iconColor": "var(--color-light-green)",
      "twoColumnHighlightedPrimaryText": "var(--color-green-light-dark)",
      "twoColumnHighlightedSecondaryText": "var(--color-green-extra-light)",
      "twoColumnHighlightedHeadingText": "var(--color-green-light-dark)"
    } as ResumeThemeModel;
  }

  private static getNairobiThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-white)",
      "pageBackground": "var(--color-extra-white-dark-light)",
      "primaryColor": "var(--color-extra-dark-brown)",
      "secondaryColor": "var(--color-extra-brown-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-extra-light)",
      "highlightedChipBackground": "var(--color-extra-primary-light)",
      "highlightedChipText": "var(--color-extra-dark-brown)",
      "iconColor": "var(--color-brown)",
      "twoColumnHighlightedPrimaryText": "var(--color-extra-dark-brown)",
      "twoColumnHighlightedSecondaryText": "var(--color-extra-brown-light)",
      "twoColumnHighlightedHeadingText": "var(--color-extra-dark-brown)"
    } as ResumeThemeModel;
  }

  private static getBerlinThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-extra-dark-brown)",
      "pageBackground": "var(--color-extra-white-dark-light)",
      "primaryColor": "var(--color-extra-dark-brown)",
      "secondaryColor": "var(--color-extra-brown-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-extra-light)",
      "highlightedChipBackground": "var(--color-extra-light)",
      "highlightedChipText": "var(--color-white)",
      "iconColor": "var(--color-brown)",
      "twoColumnHighlightedPrimaryText": "var(--color-extra-primary-light)",
      "twoColumnHighlightedSecondaryText": "var(--color-extra-brown-light)",
      "twoColumnHighlightedHeadingText": "var(--color-extra-primary-light)"
    } as ResumeThemeModel;
  }

  private static getPuneThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-purple)",
      "pageBackground": "var(--color-white-slight-blue)",
      "primaryColor": "var(--color-purple)",
      "secondaryColor": "var(--color-brown-light)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-purple-dark)",
      "highlightedChipBackground": "var(--color-purple-extra-light)",
      "highlightedChipText": "var(--color-white)",
      "iconColor": "var(--color-yellow)",
      "twoColumnHighlightedPrimaryText": "var(--color-white-slight-blue)",
      "twoColumnHighlightedSecondaryText": "var(--color-brown-light)",
      "twoColumnHighlightedHeadingText": "var(--color-white-slight-blue)"

    } as ResumeThemeModel;
  }

  private static getIstanbulThemeForTwoColumn(): ResumeThemeModel {
    return {
      "highlightedBackground": "var(--color-tertiary)",
      "pageBackground": "var(--color-white-extra-dark)",
      "primaryColor": "var(--color-tertiary)",
      "secondaryColor": "var(--color-gray)",
      "skillChipBackground": "var(--color-white-dark-light)",
      "skillChipTextColor": "var(--color-gray-dark)",
      "highlightedChipBackground": "var(--color-gray-dark)",
      "highlightedChipText": "var(--color-white)",
      "iconColor": "var(--color-extra-light-primary)",
      "twoColumnHighlightedPrimaryText": "var(--color-extra-white-dark)",
      "twoColumnHighlightedSecondaryText": "var(--color-gray)",
      "twoColumnHighlightedHeadingText": "var(--color-extra-white-dark)"
    } as ResumeThemeModel;
  }

  public static getResumeThemeSelectionModels(): ResumeThemeSelectionModel[] {
    return [
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.ISTANBUL,
        themeType: RESUME_THEME_TYPE.ISTANBUL,
        isSelected: false,
        backgroundColor: "var(--color-tertiary)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.MUMBAI,
        themeType: RESUME_THEME_TYPE.MUMBAI,
        backgroundColor: "var(--color-stroke)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.BERLIN,
        themeType: RESUME_THEME_TYPE.BERLIN,
        backgroundColor: "var(--color-extra-dark-brown)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.NAIROBI,
        themeType: RESUME_THEME_TYPE.NAIROBI,
        backgroundColor: "var(--color-white)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.PARIS,
        themeType: RESUME_THEME_TYPE.PARIS,
        backgroundColor: "var(--color-light-wheat)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.PUNE,
        themeType: RESUME_THEME_TYPE.PUNE,
        backgroundColor: "var(--color-purple)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.TOKYO,
        themeType: RESUME_THEME_TYPE.TOKYO,
        backgroundColor: "var(--color-green-light-dark)"
      },
      {
        type: PORTFOLIO_TYPE.RESUME,
        title: RESUME_THEME_TYPE_DISPLAY.MIAMI,
        themeType: RESUME_THEME_TYPE.MIAMI,
        backgroundColor: "var(--color-light-green)"
      }
    ] as ResumeThemeSelectionModel[];
  }
}
