<ng-container>
    <div class="section-title-right flex items-center justify-start resume-section-title-bottom">
        <div class="flex items-center justify-start">
            @if (!isClassicType) {
                <div class="ti ti-users text-sm sm:text-2xl"
                     [ngStyle]="{'color': resumeTheme?.iconColor}">
                </div>
            }
            <div class="flex flex-row items-center gap-2 resume-section-title"
                 [ngClass]="isClassicType ? 'pl-0' : 'pl-1' "
                 [ngStyle]="{'color': resumeTheme.primaryColor}">
                {{ sectionTitle || RESUME_SECTION_TYPE_DISPLAY.REFERENCE | titlecase }}
            </div>
        </div>
    </div>
    <hr [style.border-color]="isClassicType ? classicLayoutTheme?.borderColor : resumeTheme.iconColor "
        class="w-full horizontal-separator resume-horizontal-line-below-distance"/>
</ng-container>
<div>
    @for (resumeHasReferenceOutDto of resumeHasReferenceOutDtos; track resumeHasReferenceOutDto?.id) {
        <div class="w-full flex flex-col items-center justify-center resume-section-content-between section-card">
            <div class="w-full">
                <div class="flex items-center justify-start">
                    <div class="resume-section-container-title"
                         [ngStyle]="{'color': resumeTheme?.primaryColor}">
                        {{ resumeHasReferenceOutDto?.name | titlecase }}
                    </div>
                    @if (resumeHasReferenceOutDto?.linkedInUrl && !isClassicType) {
                        <div class="text-xxs sm:text-sm px-1 ti ti-point-filled"
                             [ngStyle]="{'color': resumeTheme?.iconColor}">
                        </div>
                    }
                    @if (resumeHasReferenceOutDto?.linkedInUrl && !isClassicType) {
                        <div class="text-sm sm:text-xl ti ti-brand-linkedin cursor-pointer"
                             [ngStyle]="{'color': resumeTheme?.iconColor}"
                             (click)="openUrlInNewTab(resumeHasReferenceOutDto?.linkedInUrl)">
                        </div>
                    }
                    @if (resumeHasReferenceOutDto?.linkedInUrl && isClassicType) {
                        <div class="ml-2 resume-section-container-text underline color-primary"
                             (click)="openUrlInNewTab(resumeHasReferenceOutDto?.linkedInUrl)">
                            Linkedin URL
                        </div>
                    }
                </div>
                <div class="resume-section-container-text"
                     [ngStyle]="{'color': resumeTheme?.secondaryColor}">
                    {{ resumeHasReferenceOutDto?.jobTitle | titlecase }}
                    @if (resumeHasReferenceOutDto?.jobTitle && resumeHasReferenceOutDto?.companyName) {
                        &#64;
                    }
                    @if (resumeHasReferenceOutDto?.companyName) {
                        <span class="resume-section-container-text"
                              [ngStyle]="{'color': resumeTheme?.secondaryColor}">
              {{ resumeHasReferenceOutDto?.companyName }}
          </span>
                    }
                </div>
                <div class="flex flex-row items-center justify-start">
                    @if (resumeHasReferenceOutDto?.phoneNumber && !isClassicType) {
                        <div class="font-normal text-xxs sm:text-sm ti ti-phone pr-1"
                             [ngStyle]="{'color': resumeTheme?.primaryColor}">
                        </div>
                    }
                    <div class="resume-section-container-text pl-1"
                         [ngStyle]="{'color': resumeTheme?.primaryColor}">
                        {{ resumeHasReferenceOutDto?.phoneNumber }}
                    </div>
                    @if (isClassicType) {
                        <div class="text-sm pl-2" [ngClass]="classicLayoutTheme?.primaryColor"> |</div>
                    }
                    @if (resumeHasReferenceOutDto?.emailAddress && !isClassicType) {
                        <div class="font-normal text-xxs sm:text-sm ti ti-mail"
                             [ngClass]="resumeHasReferenceOutDto?.phoneNumber ? 'pl-4' : ''"
                             [ngStyle]="{'color': resumeTheme?.primaryColor}">
                        </div>
                    }
                    <div class="resume-section-container-text pl-1"
                         [ngStyle]="{'color': resumeTheme?.primaryColor}">
                        {{ resumeHasReferenceOutDto?.emailAddress }}
                    </div>
                </div>
                @if (resumeHasReferenceOutDto?.description) {
                    <div class="resume-section-container-description"
                         [ngStyle]="{'color': resumeTheme?.primaryColor}"
                         [innerHTML]="resumeHasReferenceOutDto?.description">
                    </div>
                }
            </div>
        </div>
    }
</div>
